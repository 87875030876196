<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="保存" width="52.5%" @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <LImg v-if="imageUrl" :src="imageUrl" style="width: 100px; height: 100px;"/>
          <div v-if="imageUrl" class="close" @click="closeImg($event)">&times;</div>
          <a-button v-if="!imageUrl" type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'icon_image',
    label: '勋章图片',
    type: 'upload',
    rules: [{ required: true, message: '请输入勋章图片!' }]
  },
  {
    name: 'icon_name',
    label: '勋章名称',
    type: 'input',
    rules: [{ required: true, message: '请输入勋章名称!' }]
  },
]
import ImageModal from '@/views/modal/images'
import url from '@/utils/URL'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增勋章',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      imageRoot: url.imageRoot,
      imagesVisible: false,
      imageUrl: '',
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    if (this.item) {
      this.ModalTitle = '编辑勋章'
      let item = await this.getDetail(this.item.icon_id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.imageUrl = item.icon_image
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail(icon_id) {
      let res = await this.$store.dispatch('courseAchievementDetailIconAction', {data: {icon_id}})
      return res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.icon_id = item.icon_id
          }
          params.icon_image = this.imageUrl
          params.cate_id = this.parent.item.cate_id
          let res = await this.$store.dispatch(item ? 'courseAchievementUpdateIconAction' : 'courseAchievementAddIconAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        console.log(image)
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
  }
}
</script>
