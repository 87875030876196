<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading" cancelText="取消" okText="保存" width="52.5%" :footer="null" @ok="handleOk" @cancel="handleCancel">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button icon="plus" type="primary" @click="showEditModal()">新增勋章</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- <div class="list"> -->
      <draggable class="list" v-model="list" @update="datadragEnd">
        <div class="item" v-for="(item, index) of list" :key="index">
          <div class="photo">
            <LImg :src="item.icon_image" />
          </div>
          <div class="name">{{ item.icon_name }}</div>
          <div class="button">
            <a href="javascript:;" @click="showEditModal(item)">编辑</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="deleteItem(item)">删除</a>
          </div>
        </div>
      </draggable>
    <!-- </div> -->
    <editModal v-if="editVisible" :item="modalData"/>
  </a-modal>
</template>
<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  .item {
    width: 100px;
    margin: 10px;
    .photo {
      width: 100px;
      height: 100px;
      border: 1px solid #f1f1f1;
      box-sizing: border-box;
      cursor: move;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      text-align: center;
      font-size: 15px;
      padding: 5px 0;
    }
    .button {
      text-align: center;
    }
  }
}
</style>

<script>
import editModal from './editModal'
import draggable from 'vuedraggable'
export default {
  name: 'AddModal',
  provide() {
    return {
      parent: this
    }
  },
  inject: ['parent'],
  props: {
    item: Object
  },

  components: {
    draggable,
    editModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '勋章列表',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      list: [],
      editVisible: false,
    }
  },

  created() {
    this.visible = true
    this.getList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList() {
      let {cate_id} = this.item
      let res = await this.$store.dispatch('courseAchievementViewIconAction', {data: {cate_id}})
      this.list = res.data
    },
    handleOk() {
      this.parent.hideListModal(1)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    },
    
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },

    async datadragEnd (evt) {
      evt.preventDefault();
      let {cate_id} = this.item
      let data = {
        cate_id,
        sort_data: this.list
      }
      let res = await this.$store.dispatch('courseAchievementAjaxSortMadelAction', {data})
      this.$message.success('排序成功!')
    },
    
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除勋章"${item.icon_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseAchievementDeleteIconAction', { data: { icon_id: item.icon_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>