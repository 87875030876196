<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增分类</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>成就分类名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.cate_name" placeholder="成就分类名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>所属课程科目</span>
              </template>
              <a-select allowClear v-model="searchData.course_cate_id" placeholder="请选择所属课程科目" style="width: 160px">
                <a-select-option v-for="(d, index) of classCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>成就状态</span>
              </template>
              <a-select allowClear v-model="searchData.cate_status" placeholder="请选择成就状态" style="width: 160px">
                <a-select-option v-for="(d, index) of cateStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item>
          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="cate_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="images" slot-scope="text, record">
            <span @click="showListModal(record)">
              <LImg :src="text" style="width: 36px; height: 36px;"/>
            </span>
          </template>
          <template slot="is_attend" slot-scope="text,record">
              <a-switch checkedChildren="开启" unCheckedChildren="禁用" :checked="text === 1" @change="changeStatus(record)"/>
          </template>
          <template slot="action" slot-scope="text,record">
            <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
    <listModal v-if="listVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '成就分类图片', width: '150px', dataIndex: 'cate_cover', key: 'name', scopedSlots: { customRender: 'images' } },
  { title: '成就分类名称', dataIndex: 'cate_name', key: '1' },
  { title: '所属课程科目', dataIndex: 'course_cate_name', key: '2' },
  { title: '分类统计', dataIndex: 'cate_count', key: '3' },
  { title: '成就状态', dataIndex: 'cate_status', key: '4' , scopedSlots: { customRender: 'is_attend' }},
  { title: '添加时间', dataIndex: 'created_at', key: '5' },
  { title: '操作', key: 'operation', fixed: 'right', width: 90, scopedSlots: { customRender: 'action' }}
]
						
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import listModal from './list'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    listModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      cateStatus: C_ITEMS.cateStatus,
      classCategorys: [],

      searchData: {},

      visible: false,
      editVisible: false,
      listVisible: false,
      authType:['education','achievement'],
    }
  },
  mixins: [ tableMixins, authority ],
  created() {
    this.getClassCategory()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.classCategorys = res.data
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('courseAchievementAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.listVisible = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },

    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要恢复分类"${item.cate_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseAchievementRecoveryAction', { data: { cate_id: item.cate_id } })
            if (res) {
              that.getList()
              that.$message.success('操作成功!')
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },

    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要禁用分类"${item.cate_name}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('courseAchievementDeleteAction', { data: { cate_id: item.cate_id } })
            if (res) {
              that.getList()
              that.$message.success('操作成功!')
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },

    changeStatus(record){
      if (record.cate_status === 1) {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    }
  }
}
</script>
