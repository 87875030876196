<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>学员成就</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">成就分类<a-badge :count="0" /></span>
          <cjfl v-if="activeKey==='1'"/>
        </a-tab-pane>

        <!-- <a-tab-pane key="2">
          <span slot="tab">发放记录<a-badge :count="0" /></span>
          <ffjl v-if="activeKey==='2'"/>
        </a-tab-pane> -->
        <!-- <a-tab-pane key="3">
          <span slot="tab">成就统计<a-badge :count="0" /></span>
          <cjtj v-if="activeKey==='3'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import cjfl from './cjfl/index'
import ffjl from './ffjl/index'
import cjtj from './cjtj/index'

export default {
  components: {
    cjfl,
    ffjl,
    cjtj,
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

